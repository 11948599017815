
export function media_screen_(date_form) {
    let data = {}
    // media_screen_data.date_form
    // console.log(date_form, 'date_form');
    for (let i in date_form) {
        if (date_form[i] !== '' && date_form[i] !== -1) {
            data[i] = date_form[i]
        }
    }
    return data
}